import React from "react";

function Banner() {
  return (
    <div className="container-flex header-container">
      <img src="/images/holistic_candles_logo.png" alt="logo" />
    </div>
  );
}

export default Banner;
