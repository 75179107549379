function MainImage() {
  return (
    <section>
      <div className="container-fluid p-0 main-image-container">
        <img src="/images/candle-image.png" alt="main" />
        <button
          type="button"
          class="btn btn-primary lift"
          onClick={() =>
            (window.location.href = "https://cbdcandlecompany.co.uk/")
          }
        >
          Explore the Range
        </button>
      </div>
    </section>
  );
}
export default MainImage;
