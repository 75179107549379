function Footer() {
  return (
    <div className="container-fluid footer-container">
      <div className="row logo-white-row">
        <img src="/images/logowhite.png" />
      </div>
      <div className="row">
        Harewood & Milford Limited
        <br /> T/A Holistic Candles
        <br /> 86-90 Paul Street, London, England, EC2A 4NE
      </div>
      <div className="row pt-4">
        <a
          href="https://cbdcandlecompany.co.uk/policies/privacy-policy"
          style={{ color: "white" }}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}
export default Footer;
