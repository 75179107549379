import "./App.css";
import Banner from "./Components/Banner";
import Footer from "./Components/Footer";
import Form from "./Components/Form";
import ImageBlock from "./Components/ImageBlock";
import MainImage from "./Components/MainImage";
import Reviews from "./Components/Reviews";

function App() {
  return (
    <div className="App">
      <header>
        <Banner />
      </header>
      <MainImage />
      <Reviews />
      <ImageBlock />
      <Form />
      <Footer />
    </div>
  );
}

export default App;
