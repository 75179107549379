function Reviews() {
  return (
    <section className="reviews">
      <div className="container">
        <div className="pt-5">
          <img className="review-image" src="/images/stars.png" />
          <div className="pt-2">5 Star Reviews</div>
          <div className="pt-5 bold medium">Relax, unwind, uplift</div>
          <div className="block-text pt-4">
            Our range of holistic candles have been carefully blended with a
            specially selected range of scents, proven to help you relax,
            unwind, improve sleep and calm anxiety.
          </div>
          <div className="pt-5">
            <button
              type="button"
              class="btn btn-primary lift"
              onClick={() =>
                (window.location.href =
                  "https://cbdcandlecompany.co.uk/collections/cbd-candles")
              }
            >
              View all candles
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Reviews;
