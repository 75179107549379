import { useState } from "react";
import axios from "axios";

function Form() {
  const [formData, setFormData] = useState({});
  const [showDiscount, setShowDiscount] = useState(false);
  const [error, setError] = useState("");

  function updateData(e) {
    console.log(e);
    var formDataCopy = formData;
    formDataCopy[e.target.id] = e.target.value;
    setFormData(formDataCopy);
    console.log(formDataCopy);
  }
  function updateCheckBox(e) {
    var formDataCopy = formData;
    formDataCopy[e.target.id] = e.target.checked;
    setFormData(formDataCopy);
    console.log(formDataCopy);
  }

  function submit() {
    axios.defaults.withCredentials = true;
    axios
      .post("https://www.holistic-candles.co.uk/api/add_customer.php", formData)
      .then((response) => {
        console.log(response.data);
        setFormData({});
        setShowDiscount(true);
      })
      .catch((error) => {
        alert(error.response.data);
      });
  }
  return (
    <div className="container form-container">
      <div className="row pt-5 bold medium">
        Sign up & get 20% off your first order
      </div>
      <div className="row">
        <div className="main-form">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              First Name
            </label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              onChange={(e) => updateData(e)}
              aria-describedby="emailHelp"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Last Name
            </label>
            <input
              type="firstName"
              class="form-control"
              id="lastName"
              onChange={(e) => updateData(e)}
              aria-describedby="emailHelp"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Email address
            </label>
            <input
              type="email"
              class="form-control"
              id="email"
              aria-describedby="emailHelp"
              onChange={(e) => updateData(e)}
            />
          </div>

          <div class="mb-3 pt-4 pb-4 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="checkbox"
              onChange={(e) => updateCheckBox(e)}
            />
            <label class="form-check-label" for="exampleCheck1">
              I consent to receive marketing emails from Harewood and Milford
              Limited as per the{" "}
              <a href="https://cbdcandlecompany.co.uk/policies/privacy-policy">
                Privacy Policy
              </a>
              .
            </label>
          </div>
          {showDiscount && (
            <div className="large-text pb-4">
              Your discount code is: HEYIMNEW
            </div>
          )}
          <button class="btn btn-primary submit-btn" onClick={() => submit()}>
            Submit {formData[0]}
          </button>
        </div>
        <div className="ads">
          <img src="/images/ad.png" />
        </div>
      </div>
    </div>
  );
}
export default Form;
